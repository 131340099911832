<template>
  <div class="index">
    <div class="centent">
      <header class="header">
        <img src="../assets/logo.png" class="logo" alt="" srcset="" />
        <div class="header-item">
          <a @click="goAnchor('#a1')" :class="{ 'item-active': active == '#a1' }" class="item">公司简介</a>
          <a @click="goAnchor('#a2')" :class="{ 'item-active': active == '#a2' }" class="item">产品介绍</a>
          <a @click="goAnchor('#a3')" :class="{ 'item-active': active == '#a3' }" class="item">公司风采</a>
          <a @click="goAnchor('#a4')" :class="{ 'item-active': active == '#a4' }" class="item">联系我们</a>
        </div>
      </header>
      <div id="a1">
        <img class="img1" src="../assets/1.png" alt="" srcset="" />
      </div>

      <div id="a2" class="centent-item1">
        <div class="title1">产品介绍</div>
        <div class="centent-item-content-detail">
          配货回头车是一款由广州市正沅配货网络科技有限公司研发的基于手机移动互联网定位的货物跟踪平台，为用户提供全程、高效、便捷、安全的货物发货、接单、跟踪服务。配货回头车货主端，货主通过手机注册、登录，发起货物运单，并对司机发起货物跟踪。配货回头车司机端，司机通过手机端注册、登录，承运接单并接受货物跟踪。配货回头车业务员端（仅供内部使用），业务员可受理新运单、管理运单。
        </div>
        <div class="centent-item">
          <img class="img2" src="../assets/2.png" alt="" srcset="" />
          <div class="centent-item-content">
            <div class="centent-item-content-header" style="display: flex">
              <span>配货回头车货主端</span>
              <div class="button-box">
                <div class="button" @click="handleToAgreement('用户协议', 'userAgreementOfHuozhu')">用户协议</div>
                <div class="button" @click="handleToAgreement('隐私政策', 'privacyPolicyOfHuozhu')">隐私政策</div>
              </div>
            </div>
            <div class="centent-item-content-detail">
              <div class="centent-item-content-top">
                配货回头车货主端是一款为货主发货服务的软件。货主完成企业认证后，可对运单进行询价并发货，查看运单的承运进度、结算信息，对运单发表评价。货主可发布供应或者求购的瓷砖产品信息，与其他货主进行交流。货主还可查看瓷砖行业相关的企业通讯录。
              </div>
              <div style="display: flex; align-items: center">
                <span>小程序：</span>
                <img src="../assets/hzcode.jpg" style="width: 100px; height: 100px" />
              </div>
              <div>
                APP下载：
                <a href="https://turn-around.zhengyuanpeihuo.com/profile/download/huozhu.apk">https://turn-around.zhengyuanpeihuo.com/profile/download/huozhu.apk</a>
              </div>
            </div>
          </div>
        </div>
        <div class="centent-item">
          <div class="centent-item-content">
            <div class="centent-item-content-header" style="text-align: right; display: flex; justify-content: flex-end">
              <span>配货回头车司机端</span>
              <div class="button-box">
                <div class="button" @click="handleToAgreement('用户协议', 'userAgreementOfSiji')">用户协议</div>
                <div class="button" @click="handleToAgreement('隐私政策', 'privacyPolicyOfSiji')">隐私政策</div>
              </div>
            </div>
            <div class="centent-item-content-detail">
              <div class="centent-item-content-top">
                配货回头车司机端是一款为司机承运接单服务的软件。司机完成司机认证后，可查看待承运的运单并接单。接单后司机可对运单进行装货、发货、卸货等操作，查看运单结算信息，对运单发表评价。司机可管理自己的钱包，查看服务评价及订单统计。
              </div>
              <div style="text-align: right; display: flex; align-items: center; justify-content: flex-end">
                <span>小程序：</span>
                <img src="../assets/sijicode.jpg" style="width: 100px; height: 100px" />
              </div>
              <div style="text-align: right">
                APP下载：
                <a href="https://turn-around.zhengyuanpeihuo.com/profile/download/siji.apk">https://turn-around.zhengyuanpeihuo.com/profile/download/siji.apk</a>
              </div>
            </div>
          </div>
          <img class="img3" src="../assets/3.png" alt="" srcset="" />
        </div>
        <div class="centent-item">
          <img class="img2" src="../assets/7.png" alt="" srcset="" />
          <div class="centent-item-content">
            <div class="centent-item-content-header">配货回头车业务员端</div>
            <div class="centent-item-content-detail">
              <div class="centent-item-content-top">配货回头车业务员端是一个用于业务员受理新运单、管理运单等的系统，不开放注册并仅供内部使用。</div>
            </div>
          </div>
        </div>
      </div>
      <div id="a3" class="centent-item2">
        <div class="centent-item2-header">公司风采</div>
        <div class="centent-item2-img">
          <img src="../assets/img1.jpg" class="img5" alt="" />
          <img src="../assets/img2.jpg" class="img5" alt="" />
          <img src="../assets/img3.jpg" class="img5" alt="" />
        </div>
      </div>
      <div id="a4" class="centent-item3">
        <div class="centent-item3-header">联系我们</div>

        <!-- <div class="centent-item3-item">联系电话：18934311212</div> -->
        <div class="centent-item3-item">邮箱：zhengyuanpeihuo@126.com</div>
        <div class="centent-item3-item">邮政编码：510006</div>
        <div class="centent-item3-item">联系地址：广州市番禺区小谷围街明志街1号一栋301-307房信息枢纽楼3楼英诺创新空间316D6</div>
      </div>
    </div>
    <div class="footer">
      <div>
        <a target="_blank" style="color: #c9c9c9; text-decoration: none" href="https://beian.miit.gov.cn/">粤ICP备2022053804号-1</a>
      </div>
      <div style="width: 300px; margin: 0 auto">
        <a target="_blank" href="http://www.beian.gov.cn" style="display: inline-block; text-decoration: none; height: 20px; line-height: 20px">
          <img src="../assets/footer.png" style="float: left" />
          <p style="float: left; height: 20px; line-height: 20px; margin: 0px 0px 0px 5px; color: #c9c9c9">粤公网安备 44011302004147号</p>
        </a>
      </div>
      <div>©2022 广州市正沅配货网络科技有限公司 版权所有</div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();

let active = ref("#a1");
function goAnchor(selector) {
  //参数selector是id选择器（#anchor14）
  active.value = selector;
  document.querySelector(selector).scrollIntoView({
    behavior: "smooth",
  });
}
function handleToAgreement(name, key) {
  console.log("handleToAgreement", useRouter);
  let queryParam = {
    name,
    key,
  };
  router.push({ path: "/privacyAgreement", query: queryParam });
}
</script>
<style>
body {
  padding: 0;
  margin: 0;
}
.centent-item3 {
  margin-top: 74px;
}
.centent-item3-item {
  color: #333333;
  line-height: 32px;
  font-size: 16px;
  text-align: center;
}
.centent-item3-header {
  font-weight: bold;
  font-size: 28px;
  color: #2d9bed;
  margin: 0 0 16px;
  text-align: center;
}
.centent-item2 {
  /* height: 424px; */
  background: rgba(239, 239, 239, 1);
  width: 100%;
  padding: 60px 28px 71px;
}
.centent-item2-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.centent-item2-header {
  font-weight: bold;
  font-size: 28px;
  color: #2d9bed;
  margin: 0 0 57px;
  text-align: center;
}
.img4 {
  height: 199px;
  width: 289px;
  border-radius: 32px;
}
.img5 {
  /* height: 199px;
  width: 405px;
  border-radius: 32px; */
  width: 31%;
  height: 200px;
  border-radius: 32px;
}

/* .img5:nth-child(1),.img5:nth-child(2){
  margin-top: 0;
} */

.centent-item-content-header {
  margin: 35px 0 28px;
  font-weight: bold;
  color: #333333;
  font-size: 24px;
}
.centent-item-content-top {
  margin-bottom: 50px;
}
.centent-item-content-detail {
  color: #333333;
  line-height: 32px;
  font-size: 16px;
}
.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 0;
  box-sizing: content-box;
  justify-content: space-between;
}
.header-item {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.item {
  margin-left: 72px;
}
.item:hover {
  color: #3aa3ff;
}
.item-active {
  /* color:#3AA3FF; */
}
.logo {
  width: 280px;
  flex-shrink: 0;
}
.index {
  /* max-width: 1920px; */
  min-width: 1200px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.img2 {
  width: 266px;
  flex-shrink: 0;
  height: 266px;
  margin-right: 29px;
}

.img3 {
  width: 266px;
  height: 266px;
  flex-shrink: 0;
  margin-left: 29px;
}
.centent-item1 {
  margin: 74px 0;
}
.title1 {
  color: #2d9bed;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 42px;
}

.fw {
  font-weight: bold;
}

.img1 {
  width: 100%;
}

.centent-item {
  margin-top: 50px;
  color: #666;
  font-size: 20px;
  display: flex;
}

.centent {
  width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
}

.footer {
  margin-top: 80px;
  /* box-sizing: border-box; */
  box-sizing: content-box;
  flex-shrink: 0;
  color: #c9c9c9;
  font-size: 16px;
  background: #333333;
  width: 100%;
  line-height: 24px;
  text-align: center;
  padding: 36px 0;
}

.button-box {
  display: flex;
  align-items: center;
}
.button {
  color: #ffffff;
  background: #2d9bed;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 20px;
  cursor: pointer;
}
</style>
